<template>
  <div class="menu-box">
    <h2>房态管理</h2>
    <div class="box-bd">
      <div class="opt">
        <el-row>
          <el-col :span="5">
            <el-select
              @change="handleShopChange()"
              v-model="form.shop_id"
              placeholder="请选择店铺"
              clearable
              size="medium"
            >
              <el-option
                v-for="item in shopList"
                :key="item.shop_id"
                :label="item.shop_name"
                :value="item.shop_id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              @change="handleGroupChange()"
              v-model="group_id"
              placeholder="请选择房间组"
              size="medium"
            >
              <el-option
                v-for="item in roomGroupList"
                :key="item.group_id"
                :label="item.name"
                :value="item.group_id"
              >
              </el-option> </el-select
          ></el-col>
          <el-col :span="5">
            <el-button type="success" size="medium" @click="pAdd()"
              >预定房间</el-button
            >
          </el-col>
          <el-col
            style="color: #595959;font-size: 14px;height: 24px;line-height: 24px;"
            :span="9"
          >
            <el-tag class="statustag" effect="dark" size="mini" type="success">
            </el-tag>
            干净房
            <el-tag
              effect="dark"
              size="mini"
              style="border-color:#36b5ba;display: inline;padding: 0 8px;margin:0 4px;"
              color="#36b5ba"
            >
            </el-tag>
            已预订

            <el-tag class="statustag" effect="dark" size="mini" type="warning">
            </el-tag>
            入住中
            <el-tag class="statustag" effect="dark" size="mini" type="info">
            </el-tag>
            脏房
            <el-tag class="statustag" effect="dark" size="mini" type="primary">
            </el-tag>
            维修中
            <el-tag class="statustag" effect="dark" size="mini" type="danger">
            </el-tag>
            禁用
          </el-col>
        </el-row>
      </div>
      <div class="table1">
        <!-- <h3>多表头表格</h3> -->
        <more-tree-table
          :colData="colHead"
          :rowData="rowHead"
          :tableValue="tableData"
          ref="treetable"
        ></more-tree-table>
        <el-drawer
          class="eldrawer"
          :withHeader="false"
          :visible.sync="dialogVisible"
          direction="rtl"
          :before-close="handleClose"
          :wrapperClosable="false"
          :modal="false"
          size="35%"
          :modal-append-to-body="false"
        >
          <el-form :rules="formRules" ref="formRef" class="fm" :model="form">
            <el-form-item
              label="类型"
              prop="type"
              :label-width="formLabelWidth"
            >
              <el-select
                size="mini"
                @change="handleRoomTypeChange()"
                v-model="form.type"
                placeholder="选择入住类型"
                clearable
                style="width:100%"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="店铺" :label-width="formLabelWidth">
              <span style="color:red;font-size: 12px;">{{ store_name }}</span>
            </el-form-item>
            <el-form-item label="房间组" :label-width="formLabelWidth">
              <span style="color:red;font-size: 12px;">{{ room_group }}</span>
            </el-form-item>
            <el-form-item label="入住/离店时间" :label-width="formLabelWidth">
              <span style="color:red;font-size: 12px;" v-if="form.type === 1">{{
                time_range
              }}</span>
              <el-time-picker
                size="mini"
                style="width:100%"
                v-if="form.type === 1"
                v-model="form.dateRange"
                is-range
                range-separator="至"
                start-placeholder="入住时间"
                end-placeholder="离店时间"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
              >
              </el-time-picker>
              <span style="color:red;font-size: 12px;" v-else>{{
                time_range
              }}</span>
            </el-form-item>
            <el-form-item
              prop="number"
              label="入住人数"
              :label-width="formLabelWidth"
            >
              <el-input
                size="mini"
                @change="handleChange"
                v-model="form.number"
                type="number"
                min="1"
                placeholder="请填写人数"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="number"
              label="预定人姓名"
              :label-width="formLabelWidth"
            >
              <el-input
                size="mini"
                @change="handleChange"
                v-model="form.customer"
                placeholder="请填写姓名"
              ></el-input>
            </el-form-item>

            <el-form-item
              prop="phone"
              label="预留手机号"
              :label-width="formLabelWidth"
            >
              <el-input
                size="mini"
                @change="handleChange"
                v-model="form.phone"
                placeholder="请填写预留手机号"
              ></el-input>
            </el-form-item>

            <el-form-item
              v-if="addOrEdit === 2"
              prop="status"
              label="状态"
              :label-width="formLabelWidth"
            >
              <el-switch
                @change="handleChange"
                v-model="form.status"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
            </el-form-item>
          </el-form>

          <div class="footer">
            <el-button size="mini" @click="cancelAE()">取 消</el-button>
            <el-button
              size="mini"
              type="success"
              :loading="btnLoading"
              @click="confirmAE()"
              >确 定</el-button
            >
          </div>
        </el-drawer>

        <el-drawer
          @close="handleDrawerClose"
          class="eldrawer1"
          :withHeader="false"
          :visible.sync="bottomDrawerVisible"
          direction="rtl"
          :modal="false"
          size="35%"
          :modal-append-to-body="false"
        >
          <div v-if="detailInfo.type === 0">
            <el-descriptions
              class="margin-top"
              title="基本信息"
              :column="1"
              size="medium"
              border
            >
              <template slot="extra">
                <el-row>
                  <el-button
                    type="primary"
                    @click="checkin(detailInfo)"
                    size="mini"
                    >录入信息</el-button
                  >
                </el-row>
              </template>
              <el-descriptions-item>
                <template slot="label">
                  预订人
                </template>
                {{ detailInfo.customer }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  手机号
                </template>
                {{ detailInfo.phone }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  类型
                </template>
                <el-tag size="mini" type="success" v-if="detailInfo.type === 0"
                  >全天房</el-tag
                >
                <el-tag size="mini" type="warning" v-if="detailInfo.type === 1"
                  >钟点房</el-tag
                >
              </el-descriptions-item>
              <el-descriptions-item v-if="detailInfo.list">
                <template slot="label">
                  房间
                </template>
                {{ detailInfo.list[0].room_name }}
              </el-descriptions-item>
              <el-descriptions-item v-if="detailInfo.list">
                <template slot="label">
                  规格
                </template>
                {{ detailInfo.list[0].spec_key_name }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  入住/离店时间
                </template>
                {{ detailInfo.start_time }} - {{ detailInfo.end_time }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  支付
                </template>
                {{ detailInfo.pay }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  价格
                </template>
                {{ detailInfo.price }}
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions
              style="margin-top:20px"
              class="margin-top"
              title="入住信息"
              :column="1"
              size="medium"
              border
            ></el-descriptions>
            <el-tag
              size="small"
              type="success"
              v-if="
                detailInfo.list && detailInfo.list[0].check_list.length === 0
              "
              >暂无住客信息，请添加</el-tag
            >
            <!-- {{ detailInfo }} -->
            <el-table
              border
              v-if="detailInfo.list && detailInfo.list[0].check_list.length > 0"
              class="detail-table"
              :data="detailInfo.list[0].check_list"
            >
              <el-table-column label="姓名" prop="customer"> </el-table-column>
              <el-table-column label="身份证号" prop="id_number">
              </el-table-column>
            </el-table>
          </div>
          <div v-else>
            <div>
              <el-tabs v-model="activeName" @tab-click="handleTabClick">
                <el-tab-pane
                  v-for="(item, index) in taglist"
                  :key="index"
                  :label="item.tag"
                  :name="item.order_id.toString()"
                >
                  <div v-loading="tabLoading">
                    <el-descriptions
                      class="margin-top"
                      title="基本信息"
                      :column="1"
                      size="medium"
                      border
                    >
                      <template slot="extra">
                        <el-button
                          type="primary"
                          size="mini"
                          @click="checkin(detailInfo, index)"
                          >录入信息</el-button
                        >
                      </template>
                      <el-descriptions-item>
                        <template slot="label">
                          预订人
                        </template>
                        {{ detailInfo.customer }}
                      </el-descriptions-item>
                      <el-descriptions-item>
                        <template slot="label">
                          手机号
                        </template>
                        {{ detailInfo.phone }}
                      </el-descriptions-item>
                      <el-descriptions-item>
                        <template slot="label">
                          类型
                        </template>
                        <el-tag
                          size="mini"
                          type="success"
                          v-if="detailInfo.type === 0"
                          >全天房</el-tag
                        >
                        <el-tag
                          size="mini"
                          type="warning"
                          v-if="detailInfo.type === 1"
                          >钟点房</el-tag
                        >
                      </el-descriptions-item>
                      <el-descriptions-item v-if="detailInfo.list">
                        <template slot="label">
                          房间
                        </template>
                        {{ detailInfo.list[0].room_name }}
                      </el-descriptions-item>
                      <el-descriptions-item v-if="detailInfo.list">
                        <template slot="label">
                          规格
                        </template>
                        {{ detailInfo.list[0].spec_key_name }}
                      </el-descriptions-item>
                      <el-descriptions-item>
                        <template slot="label">
                          入住/离店时间
                        </template>
                        {{ detailInfo.start_time }} - {{ detailInfo.end_time }}
                      </el-descriptions-item>
                      <el-descriptions-item>
                        <template slot="label">
                          支付
                        </template>
                        {{ detailInfo.pay }}
                      </el-descriptions-item>
                      <el-descriptions-item>
                        <template slot="label">
                          价格
                        </template>
                        {{ detailInfo.price }}
                      </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions
                      style="margin-top:20px"
                      class="margin-top"
                      title="入住信息"
                      :column="1"
                      size="medium"
                      border
                    ></el-descriptions>
                    <el-tag
                      size="small"
                      type="success"
                      v-if="
                        detailInfo.list &&
                          detailInfo.list[0].check_list.length === 0
                      "
                      >暂无住客信息，请添加</el-tag
                    >
                    <!-- {{ detailInfo }} -->
                    <el-table
                      border
                      v-if="
                        detailInfo.list &&
                          detailInfo.list[0].check_list.length > 0
                      "
                      class="detail-table"
                      :data="detailInfo.list[0].check_list"
                    >
                      <el-table-column label="姓名" prop="customer">
                      </el-table-column>
                      <el-table-column label="身份证号" prop="id_number">
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
    <el-dialog
      v-dialogDrag
      title="录入顾客信息"
      :visible.sync="infodialogVisible"
      :before-close="formClose"
    >
      <el-row>
        <el-button type="success" @click="addInfo" size="mini">+增加</el-button>
      </el-row>
      <el-table :data="infoform.customer_info">
        <el-table-column label="客户姓名" prop="customer">
          <template v-slot="scope">
            <el-input
              size="mini"
              style="width:100px"
              @change="handleChange"
              v-model="scope.row.customer"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column label="身份证号" prop="id_number">
          <template v-slot="scope">
            <el-input
              size="mini"
              min="1"
              type="number"
              style="width:180px"
              @change="handleChange"
              v-model="scope.row.id_number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button
              size="mini"
              type="danger"
              @click="delInfo(scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelInfoAE()">取 消</el-button>
        <el-button
          type="primary"
          :loading="infoBtnLoading"
          @click="confirmInfoAE()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lodash from 'lodash'
import MoreTreeTable from '../../../components/freeTable/MoreTreeTable.vue'
import moreTreeTableJs from '../../../components/freeTable/moreTreeTableJs'
import {
  getRoomGroupList,
  getStoreList,
  specModelList,
  doneCleaning,
} from '@/api/room/roommanage.js'
import {
  subscribeRoom,
  setCleaning,
  setRepair,
  getRoomSpecCol,
  showRoomState,
  getOrderList,
  orderInfo,
  checkin,
  getAllItems,
} from '@/api/room/roomstatemanage.js'
export default {
  components: {
    MoreTreeTable,
  },

  data() {
    return {
      itemstr: '',
      id: '',
      list: [],
      price_id: '',
      infoBtnLoading: false,
      infodialogVisible: false,
      tabLoading: false,
      taglist: [],
      activeName: '订单1',
      bottomDrawerVisible: false,
      rowHead: [],
      moreTreeTableJs: moreTreeTableJs,
      colHead: [],
      tableData: {},
      //   rowHead: moreTreeTableJs.rowHead,
      tableValue1: {},
      tableValue2: moreTreeTableJs.tableValue2,
      tableValue3: moreTreeTableJs.tableValue3,
      dialogVisible: false,
      group_id: 0,
      title: '预订房间',
      typeList: [
        { id: 0, name: '全天房' },
        { id: 1, name: '钟点房' },
      ],
      formLabelWidth: '100px',
      btnLoading: false,
      addOrEdit: 1,
      form: {
        type: 0,
        number: 1,
        shop_id: 5,
        start_time: '',
        end_time: '',
        customer: '',
        phone: '',
        model_id: '',
      },
      roomGroupList: [],
      shopList: [],
      modelList: [],
      formRules: {
        type: {
          required: true,
          message: '请填选择房间类型',
          trigger: 'blur',
        },
        number: {
          required: true,
          message: '请填写入住人数',
          trigger: 'blur',
        },
        customer: {
          required: true,
          message: '请填写预订人姓名',
          trigger: 'blur',
        },
        phone: {
          required: true,
          message: '请填写预留手机号',
          trigger: 'blur',
        },
      },

      checkedInfo: [],
      continue_days: [],
      sort_date: [],
      room_group: '',
      store_name: '',
      time_range: '',
      detailInfo: {},
      clocklist: [],
      infoform: {
        order_id: '',
        customer_info: [],
      },
    }
  },
  computed: {},

  created() {
    this.getOptionList()
  },
  mounted() {
    this.getRoomState()
    this.handleShopChange().then(() => {
      this.handleGroupChange()
    })
    this.generateDate()
    this.$EventBus.$on('sendCheckedInfo', (checkedInfo) => {
      this.checkedInfo = checkedInfo
      console.log(this.checkedInfo)
      this.checkedInfo.forEach((item) => {
        this.sort_date.push(item.date)
      })
      this.sort_date = this.sort_date.sort()
    })

    this.$EventBus.$on('seeDetail', (id, list) => {
      this.seeDetail(id, list)
      this.id = id
      this.list = list
    })
    this.$EventBus.$on('doneClean', (roomid, priceid) => {
      this.doneCleaning(roomid, priceid)
    })
  },

  methods: {
    doneCleaning(roomid, priceid) {
      this.$confirm(`确认已清洁${this.itemstr},并完成保洁？`)
        .then(async (_) => {
          const { data: res } = await doneCleaning({
            room_id: Number(roomid),
            price_id: Number(priceid),
          })
          if (res.code === 1) {
            this.$message.success('已完成保洁！')
            // this.getList()
            this.getRoomState()
          } else {
            this.$message.error(res.message)
          }

          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    cancelInfoAE() {
      //this.detailInfo = {}
      this.infoform = {
        order_id: '',
        customer_info: [],
      }
      this.infodialogVisible = false
    },
    async confirmInfoAE() {
      // 添加
      let query = lodash.cloneDeep(this.infoform)
      console.log(query)
      query.customer_info = JSON.stringify(query.customer_info)
      var formData = new FormData()
      for (let key in query) {
        formData.append(key, query[key])
      }
      this.infoBtnLoading = true
      console.log(query)
      const { data: res } = await checkin(formData)
      if (res.code === 1) {
        this.$message.success('录入成功')
        this.cancelInfoAE()
        if (this.detailInfo.type === 0) {
          this.seeDetail(this.id, this.list)
        } else {
          this.handleTabClick()
        }
      } else {
        this.$message.error(res.message)
      }
      this.infoBtnLoading = false
    },
    colorType(status) {
      if (status === 0) {
        return '#f56c6c'
      } else if (status === 1) {
        return '#67c23a'
      } else if (status === 2) {
        return '#e6a23c'
      } else if (status === 3) {
        return '#909399'
      } else if (status === 4) {
        return '#409eff'
      } else if (status === 5) {
        return '#36b5ba'
      }
    },
    checkin(info, index) {
      if (info.type === 0) {
        this.infodialogVisible = true
        this.infoform.order_id = info.order_id
        this.price_id = info.list[0].price_id
        this.infoform.customer_info.push({
          price_id: this.price_id,
          customer: '',
          id_number: '',
        })
      } else if (info.type === 1) {
        console.log(index)
        this.infodialogVisible = true
        this.infoform.order_id = Number(this.activeName)
        if (this.clocklist.length > 0) {
          this.price_id = info.list[0].price_id
          console.log(this.price_id)
        }
        this.infoform.customer_info.push({
          price_id: this.price_id,
          customer: '',
          id_number: '',
        })
      }
    },
    delInfo(row, index) {
      console.log(row, index)
      this.infoform.customer_info.splice(index, 1)
    },
    async getRoomState() {
      const { data: res } = await showRoomState()
      console.log(res)
      let info = res.data
      for (let key in info) {
        if (info[key].length > 0) {
          let map = new Map()
          let clock_room_memo = []
          info[key].forEach((item) => {
            let type = ''

            if (item.type === 0) {
              type = '全天'
            } else {
              type = '钟点'
              let id = item.room_id
              if (map.has(id)) {
                let val = map.get(id)
                map.set(id, ++val)
              } else {
                map.set(id, 1)
              }
              clock_room_memo.push(item)
            }
            let spanstr = ``
            if (item.spec_status === 3) {
              spanstr = `<div style='position:absolute;right:0;bottom:0' ><i data-roomid='${item.room_id}' data-priceid='${item.price_id}' style='color:green;cursor:pointer' class='el-icon-circle-check'></i></div>`
            }
            let colortype = this.colorType(item.spec_status)
            if (type === '全天') {
              let list = []
              this.$set(
                this.tableData,
                key + '__' + item.price_id,
                `<span>${type} ` +
                  `${item.price}` +
                  `￥</span>` +
                  `<span
                        style="display:inline-block;margin-left: 4px;width:14px;height:14px;border-radius:3px;background-color:${colortype}"
                    
                    > </span>` +
                  `<div style='position:absolute;right:0;top:0' >
                        <i data-orderid='${item.order_id}' data-list='${list}' style='color:green;cursor:pointer' class='el-icon-search'></i>
                        </div>` +
                  spanstr
              )
            } else {
              // 钟点房
              // map.forEach((val,key)=>{

              // })
              // console.log()
              let value = map.get(item.room_id)
              //  console.log(value)
              let filterarr = clock_room_memo.filter((i) => {
                return i.room_id === item.room_id
              })
              // console.log(JSON.stringify(filterarr))
              let a = JSON.stringify(filterarr)
              this.$set(
                this.tableData,
                key + '__' + item.price_id,
                `<span>${type} ` +
                  `(${value})` +
                  ` ` +
                  `${item.price}` +
                  `￥</span>` +
                  `<span
                        style="display:inline-block;margin-left: 4px;width:14px;height:14px;border-radius:3px;background-color:${colortype}"
                    
                    > </span>` +
                  `<div style='position:absolute;right:0;top:0' >
                        <i data-orderid='${item.order_id}' data-list='${a}'  style='color:green;cursor:pointer' class='el-icon-search'></i>
                        </div>`
              )
            }
          })
          //  console.log(Object.fromEntries(map))
          //  console.log(clock_room_memo)
        }
      }
      this.$store.commit('setRoomTableData', this.tableData)
    },
    async handleTabClick() {
      this.tabLoading = true
      if (this.clocklist.length > 0) {
        const { data: res } = await orderInfo({
          order_id: Number(this.activeName),
        })
        if (res.code === 1) {
          this.detailInfo = res.data
        } else {
          this.$message.error(res.message)
        }
        this.tabLoading = false
      }
    },
    async seeDetail(id, list) {
      if (list) {
        // 传了数组，
        this.clocklist = JSON.parse(list)
        console.log(this.clocklist)
        let num = 1
        this.clocklist.forEach((item) => {
          this.taglist.push({ tag: `订单` + num, order_id: item.order_id })
          num++
        })
        if (this.taglist.length > 0) {
          this.activeName = this.taglist[0].order_id.toString()
        }
        if (this.clocklist.length > 0) {
          const { data: res } = await orderInfo({
            order_id: this.clocklist[0].order_id,
          })
          if (res.code === 1) {
            this.detailInfo = res.data
          }
        }
        this.bottomDrawerVisible = true
      } else {
        //console.log(id)
        const { data: res } = await orderInfo({ order_id: id })
        console.log(res)
        this.bottomDrawerVisible = true
        if (res.code === 1) {
          this.detailInfo = res.data
        }
      }
    },
    handleRoomTypeChange() {
      if (this.sort_date.length > 1) {
        //只选择了一个日期
        if (this.form.type === 1) {
          //钟点房
          this.$message.info('钟点房仅支持单天预定，请重新选择')
          this.dialogVisible = false
          return
        }
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 新增打开
    pAdd() {
      this.$EventBus.$emit('emitClick')

      if (this.checkedInfo.length === 0) {
        this.$message.info('还未选择房间，请先至少勾选一个房间')
        return
      }
      this.roomGroupList.forEach((item) => {
        //console.log(item)
        if (item.group_id === this.group_id) {
          this.room_group = item.name
        }
      })
      this.shopList.forEach((item) => {
        if (item.shop_id === this.form.shop_id) {
          console.log('===')
          console.log(item)
          this.store_name = item.shop_name
        }
      })

      let len = this.sort_date.length
      console.log(len)
      if (this.form.type === 0) {
        if (len > 1) {
          this.time_range = this.sort_date[0] + ' 至 ' + this.sort_date[len - 1]
          console.log(this.time_range)
        } else if (len === 1) {
          this.time_range = this.sort_date[0] + ' --- ' + this.sort_date[0]
        }
      } else {
        this.time_range = this.sort_date[0]
      }
      this.dialogVisible = true
    },

    generateDate() {
      let today = moment().format('YYYY/MM/DD')
      let day31 = moment()
        .subtract(31, 'days')
        .format('YYYY-MM-DD')
      //  console.log(day31)
      for (let i = 3; i > 0; i--) {
        this.rowHead.push({
          id: moment()
            .subtract(i, 'days')
            .format('YYYY/MM/DD'),
          name: moment()
            .subtract(i, 'days')
            .format('YYYY/MM/DD'),
        })
      }
      this.rowHead.push({
        id: today,
        name: today,
      })
      for (let i = 0; i < 180; i++) {
        this.rowHead.push({
          id: moment()
            .add(i, 'days')
            .format('YYYY-MM-DD'),
          name: moment()
            .add(i, 'days')
            .format('YYYY-MM-DD'),
        })
      }
    },
    async handleShopChange() {
      const { data: res1 } = await getRoomGroupList({
        shop_id: this.form.shop_id,
      })
      if (res1.code === 1) {
        this.roomGroupList = res1.data
      } else {
        this.$message.error('获取房间组数据失败！')
      }
    },
    async getOptionList() {
      const { data: res } = await getStoreList()
      // console.log(res)
      if (res.code === 1) {
        this.shopList = res.data
      } else {
        this.$message.error('获取店铺数据失败！')
      }
      const { data: res2 } = await specModelList()

      if (res2.code === 1) {
        this.modelList = res2.data
      } else {
        this.$message.error('获取房间模型失败！')
      }

      const { data: res1 } = await getAllItems({ type: 0 })
      if (res1.code === 1) {
        let r = res1.data
        r.forEach((item) => {
          this.itemstr = this.itemstr + ' ' + item.name
        })
        console.log(this.itemstr)
      } else {
        this.$message.error('获取保洁事项失败！')
      }
    },
    handleChange() {
      this.isEdit = true
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelInfoAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },

    handleDrawerClose() {
      this.taglist = []
    },
    addInfo() {
      this.infoform.customer_info.push({
        price_id: this.price_id,
        customer: '',
        id_number: '',
      })
    },
    cancelAE() {
      this.title = '预订房间'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        type: 0,
        number: 1,
        shop_id: '',
        start_time: '',
        end_time: '',
        customer: '',
        phone: '',
        model_id: '',
        dateRange: '',
      }
      this.sort_date = []
      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },
    async handleGroupChange() {
      const { data: res } = await getRoomSpecCol({
        group_id: this.group_id,
      })
      this.colHead = res.data
      this.colHead.forEach((item) => {
        let child = lodash.cloneDeep(item.spec_list)

        this.$set(item, 'children', child)
        item.children.forEach((i) => {
          this.$set(i, 'name', i.spec_key_name)
          this.$set(i, 'id', i.room_spec_price_id)
        })
      })
    },
    async confirmAE() {
      let query = {}
      query.type = this.form.type
      query.shop_id = this.form.shop_id
      query.customer = this.form.customer
      query.phone = this.form.phone
      query.number = this.form.number
      query.room_spec_arr = []
      //   this.colHead.forEach(item=>{

      //   })
      if (this.form.type === 0) {
        // 全天房
        query.start_time = this.sort_date[0]
        let len = this.sort_date.length
        query.end_time = this.sort_date[len - 1]
      } else {
        // 钟点房
        query.start_time = this.sort_date[0] + ' ' + this.form.dateRange[0]
        query.end_time = this.sort_date[0] + ' ' + this.form.dateRange[1]
      }
      let price_id = this.checkedInfo[0].roomspec
      let room_id = ''
      this.colHead.forEach((item) => {
        if (item.children.length > 0) {
          item.children.forEach((i) => {
            if (i.room_spec_price_id == price_id) {
              room_id = i.room_id
            }
          })
        }
      })
      query.room_spec_arr.push({
        group_id: this.group_id,
        price_id,
        room_id,
      })
      console.log(query)
      query.room_spec_arr = JSON.stringify(query.room_spec_arr)
      let formData = new FormData()
      for (let key in query) {
        formData.append(key, query[key])
      }
      console.log(formData)
      const { data: res } = await subscribeRoom(formData)
      console.log(res)
      if (res.code === 1) {
        this.$message.success(res.message)
        this.getRoomState()
        this.$EventBus.$emit('clearCheckedInfo')
        this.dialogVisible = false
      } else {
        this.$message.error(res.message)
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: 50px 50px;
}
.eldrawer {
  position: absolute;
  z-index: 100 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  top: 44px !important;
  height: 450px;
}
.eldrawer1 {
  position: absolute;
  overflow: scroll;
  z-index: 100 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  top: 44px !important;
  height: 450px;
}
.box-bd {
  height: calc(100vh - 140px);
  overflow: scroll;
}
.table1 {
  margin-top: 15px;
  height: 100%;
  position: relative;
}

.table1 >>> .el-drawer.rtl {
  box-shadow: none !important;
  border-radius: 5px;
  padding: 20px;
}
.table1 >>> .el-drawer__body {
  overflow: scroll;
}

.el-drawer.rtl {
  top: 44px !important;
}
.chose-group {
  position: absolute;
  top: 140px;
  left: 50px;
  z-index: 999;
}
.fm >>> .el-form-item__label {
  font-size: 12px !important;
}
.fm >>> .el-form-item {
  margin-bottom: 5px;
}
.footer {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
.statustag {
  display: inline;
  padding: 0 8px;
  margin: 0 4px;
}
</style>
