import { post } from '@/utils/request'

//添加预定
export function subscribeRoom(data) {
    return post('/room/room/subscribeRoom', data)
}
//获取房间规格信息
export function getRoomSpecCol(data) {
    return post('/room/room/roomList2', data)
}
//展示预定房间信息
export function showRoomState(data) {
    return post('/room/room/orderList', data)
}
//获取订单列表
export function getOrderList(data) {
    return post('/room/room/order', data)
}
//获取订单详情
export function orderInfo(data) {
    return post('/room/room/orderInfo', data)
}
// 入住信息录入
export function checkin(data) {
    return post('/room/room/checkIn', data)
}
// 退房
export function checkout(data) {
    return post('/room/room/checkout', data)
}
export function getAllItems(data) {
    return post('/room/work_Item/selWorkItem', data)
}
