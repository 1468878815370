<template>
  <div class="value-table">
    <!-- 多表头表体 -->
    <table
      class="editTable__block"
      v-if="edit.rowData.length > 0 && edit.colData.length > 0"
    >
      <tr v-for="(x, xindex) in edit.lastDataCol" :key="xindex">
        <td
          v-for="(y, yindex) in edit.lastDataRow"
          :key="yindex + '_' + xindex"
          @click.stop="handleValueClick(y.id, x.id)"
          :style="tdStyle"
          :ref="y.id + x.id"
        >
          <div
            id="signal"
            style="color:#fff;border-radius:3px;position: absolute;left:0;top:0;width:12px;height:12px;background-color:red;display: none;"
          ></div>
          <div
            @click.prevent="handleSearchClick($event)"
            v-html="tableData[y.id + '__' + x.id] || ''"
          ></div>
        </td>
      </tr>
    </table>

    <!-- 横向表头表体 -->
    <table
      class="editTable__block"
      v-if="edit.rowData.length > 0 && edit.colData.length === 0"
    >
      <tr v-for="(x, xindex) in tableData" :key="xindex">
        <td
          v-for="(y, yindex) in edit.lastDataRow"
          :key="yindex + '_' + xindex"
          :style="tdStyle"
        >
          {{ x[y.id] || '' }}
        </td>
      </tr>
    </table>

    <!-- 纵向表头表体 -->
    <table
      class="editTable__block"
      v-if="edit.rowData.length === 0 && edit.colData.length > 0"
    >
      <tr v-for="(x, xindex) in edit.lastDataCol" :key="xindex">
        <td
          v-for="(y, yindex) in tableData"
          :key="yindex + '_' + xindex"
          :style="tdStyle"
        >
          {{ y[x.id] || '' }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  inject: ['edit'],
  props: {
    tableData: [Object, Array],
  },
  watch: {
    tableData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.valueData = val
        this.tableData = val
      },
    },
    valueData: {
      deep: true,
      handler(val) {
        this.$emit('updata:tableData', val)
      },
    },
  },
  mounted() {
    //this.sendCheckedInfo()
    this.$EventBus.$on('emitClick', () => {
      this.sendCheckedInfo()
    })
    this.$EventBus.$on('clearCheckedInfo', () => {
      this.clearCheckedInfo()
    })
  },
  data() {
    return {
      //   cross: "<div style='width:5px;height:5px;background-color:red;'></div>",
      valueData: {},
      checkedInfo: [],
      continue_days: [],
      yidMemo: '',
      count: 0,
      signalVisible: false,
      tdStyle: {
        width: this.edit.headColWidth + 'px',
        height: this.edit.tableTdHeight + 'px',
        textAlign: 'center',
        cursor: 'pointer',
        position: 'relative',
      },
    }
  },
  methods: {
    handleSearchClick(e) {
      //   console.log(e.target)
      e.stopPropagation()
      // console.log(e.target.dataset.orderid)
      if (e.target.className === 'el-icon-search') {
        this.$EventBus.$emit(
          'seeDetail',
          e.target.dataset.orderid,
          e.target.dataset.list
        )
      }
      if (e.target.className === 'el-icon-circle-check') {
        this.$EventBus.$emit(
          'doneClean',
          e.target.dataset.roomid,
          e.target.dataset.priceid
        )
      }
    },
    sendCheckedInfo() {
      this.continue_days = []
      this.checkedInfo.forEach((item) => {
        this.continue_days.push(item.date)
      })
      let ifcontinue = this.continueDays(this.continue_days)
      console.log(ifcontinue)
      if (!ifcontinue) {
        this.$message.info('请选择连续日期，不连续日期请分别预定')
        return
      } else {
        this.$EventBus.$emit('sendCheckedInfo', this.checkedInfo)
      }
    },
    clearCheckedInfo() {
      //this.checkedInfo = []
      //   this.$refs[yid + xid][0].querySelector('#signal').style.display = 'none'

      console.log(this.$refs)
      this.checkedInfo.forEach((item) => {
        console.log(this.$refs[item.date + item.roomspec])
        this.$refs[item.date + item.roomspec][0].querySelector(
          '#signal'
        ).style.display = 'none'
      })
      setTimeout(() => {
        this.checkedInfo = []
      }, 0)
      this.$forceUpdate()
    },
    // 判断日期是否连续
    continueDays(arr_days) {
      // 先排序，然后转时间戳

      let days = arr_days.sort().map((d, i) => {
        let dt = new Date(d)

        dt.setDate(dt.getDate() + 4 - i) // 处理为相同日期

        // 抹去 时 分 秒 毫秒

        dt.setHours(0)

        dt.setMinutes(0)

        dt.setSeconds(0)

        dt.setMilliseconds(0)

        return +dt
      })

      let ret = true

      days.forEach((d) => {
        if (days[0] !== d) {
          ret = false
        }
      })

      return ret
    },
    change(data) {
      this.$emit('tdChange', data)
    },

    handleValueClick(yid, xid) {
      if (this.yidMemo === '' || this.count === 0) {
        this.yidMemo = xid
      } else {
        if (this.yidMemo !== xid) {
          this.$message({
            message: '只能选择该房间下的一个规格进行预定',
            type: 'info',
            duration: '2000',
          })
          return
        }
      }
      // 如果已经被全天预定，不能再点击
      let tableData = this.$store.state.room_table_data
      console.log(tableData)
      for (let key in tableData) {
        if (yid + '__' + xid === key && tableData[key].indexOf('全天') > -1) {
          //全天预定
          this.$message({
            message: '该房已被全天预定，请选择其它房间预定',
            type: 'info',
            duration: '2000',
          })
          return
        }
      }
      if (
        this.$refs[yid + xid][0].querySelector('#signal').style.display ===
        'block'
      ) {
        this.$refs[yid + xid][0].querySelector('#signal').style.display = 'none'
        this.count--
        this.checkedInfo = this.checkedInfo.filter((i) => {
          // console.log(i.roomspec)
          //  console.log(xid)
          return i.date != yid
        })
        // console.log(this.checkedInfo)
      } else {
        this.$refs[yid + xid][0].querySelector('#signal').style.display =
          'block'
        this.count++
        this.checkedInfo.push({
          roomspec: xid,
          date: yid,
        })
        console.log(this.checkedInfo)
      }
      //   console.log(this.count)
      console.log(this.$refs)
    },
  },
}
</script>

<style lang="scss">
.value-table {
  table {
    table-layout: fixed;
    min-width: 100%;
  }
  .el-table td {
    padding: 0 !important;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-table th {
    padding: 0 !important;
    height: 39px;
    line-height: 39px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-table .cell {
    padding: 0;
    padding-left: 0;
  }
  .el-input__inner {
    border: 0;
    background: transparent;
  }
}

// }
</style>
