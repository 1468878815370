export default{
    rowHead: [
        {
            id: "2023/06/07",
            name: "2023/06/07",
          
        },
        {
            id: "2023/06/08",
            name: "2023/06/08",
      
        },
        {
            id: "2023/06/09",
            name: "2023/06/09",
      
        },
        {
            id: "2023/06/10",
            name: "2023/06/10",
      
        },
        {
            id: "2023/06/11",
            name: "2023/06/11",
      
        },
        {
            id: "2023/06/12",
            name: "2023/06/12",
      
        },
        {
            id: "2023/06/13",
            name: "2023/06/13",
      
        },
    ],
    colHead: [
        {
            id: "1111",
            name: "测试1",
            children: [
                {
                    id: "1111-1",
                    name: "测试1-1",
                    children: [
                        {
                            id: "1111-1-2",
                            name: "测试1-1-2",
                        },
                        {
                            id: "1111-1-3",
                            name: "测试1-1-4",
                        },
                    ]
                },
                {
                    id: "1111-2",
                    name: "测试1-2",
                    children: [
                        {
                            id: "1111-2-2",
                            name: "测试1-2-2",
                        },
                        {
                            id: "1111-2-3",
                            name: "测试1-2-4",
                        },
                    ]
                },
                {
                    id: "1111-3",
                    name: "测试1-3",
                    children: [
                        {
                            id: "1111-3-2",
                            name: "测试1-3-2",
                        },
                        {
                            id: "1111-3-3",
                            name: "测试1-3-4",
                        },
                    ]
                },
                {
                    id: "1111-4",
                    name: "测试1-4",
                },
                
            ]
        },
        {
            id: "2222",
            name: "测试1",
            children: [
                {
                    id: "2222-1",
                    name: "测试1-1",
                    children: [
                        {
                            id: "2222-1-2",
                            name: "测试1-1-2",
                        },
                        {
                            id: "2222-1-3",
                            name: "测试1-1-4",
                        },
                    ]
                },
                {
                    id: "2222-2",
                    name: "测试1-2",
                    children: [
                        {
                            id: "2222-2-2",
                            name: "测试1-2-2",
                        },
                        {
                            id: "2222-2-3",
                            name: "测试1-2-4",
                            children: [
                                {
                                    id: "2222-2-3-1",
                                    name: "测试1-2-2",
                                },
                                {
                                    id: "2222-2-3-2",
                                    name: "测试1-2-2",
                                },
                            ]
                        },
                    ]
                },
                {
                    id: "2222-3",
                    name: "测试1-3",
                    children: [
                        {
                            id: "2222-3-2",
                            name: "测试1-3-2",
                        },
                        {
                            id: "2222-3-3",
                            name: "测试1-3-4",
                        },
                    ]
                },
                {
                    id: "2222-4",
                    name: "测试1-4",
                },
                
            ]
        },
    ],
    //多项表头的数据
    tableValue1: {
        // '86__2023/06/07': '√',
        '2023/05/16__98': '√',
        // 行id 列id
        // '1111-1-3__1111-1-3': '√',
        // '1111-2-2__1111-2-2': '√',
        // '1111-2-3__1111-2-3': '√',
        // '1111-3-2__1111-3-2': '√',
        // '2222-3-3__2222-3-3': "<h1 style='color:red'>2241234</h1>",
        // '2222-4__2222-4': '√'
    },
    //纵向表头数据
    tableValue2: [
        {
            '1111-1-2': "1",
            '1111-1-3': "2",
            '1111-2-2': "3",
            '1111-2-3': "4",
            '1111-3-2': "5",
            '2222-3-3': "6",
            '2222-4': "7"
        },
        {},
        {},
        {},
        {}
    ],
    //横向表头数据
    tableValue3: [
        {
            '1111-1-2': "1",
            '1111-1-3': "2",
            '1111-2-2': "3",
            '1111-2-3': "4",
            '1111-3-2': "5",
            '2222-3-3': "6",
            '2222-4': "7"
        },
        {},
        {},
        {},
        {}
    ],
}